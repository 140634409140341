import { gql } from '@apollo/client';

const PRODUCTS_BUNDLES_APPLICABLE_RULES_QUERY = gql`
    query ProductsAndBundlesApplicableRules(
        $productIds: [String]
        $sku: [String]
        $plmId: [String]
        $bundlesID: [String!]
        $firstGtins: Int
        $firstBundles: Int
        $storeId: ID
    ) {
        products(productId: $productIds, plmId: $plmId, sku: $sku, first: $firstGtins) {
            totalCount
            edges {
                cursor
                node {
                    id
                    gtin
                    applicableRules(storeId: $storeId) {
                        id
                        title
                        campaignIcon
                        priority
                        conditions {
                            id
                            includeAll
                            type
                            evaluationType
                            objects
                        }
                    }
                }
            }
        }
        bundles(bundleId: $bundlesID, first: $firstBundles) {
            totalCount
            edges {
                cursor
                node {
                    id
                    sku
                    applicableRules(storeId: $storeId) {
                        id
                        title
                        campaignIcon
                        priority
                        conditions {
                            id
                            includeAll
                            type
                            evaluationType
                            objects
                        }
                    }
                }
            }
        }
    }
`;

export default PRODUCTS_BUNDLES_APPLICABLE_RULES_QUERY;
