import { gql } from '@apollo/client';
import { ProductReviewFieldsFragment } from './ProductReviewFields.gql';

/**
 * GraphQL Fragment
 *
 */
export const ProductFieldsFragment = gql`
    ${ProductReviewFieldsFragment}
    fragment ProductFields on Product {
        id
        gtin
        name
        slug
        brand
        seoDescription
        description
        longDescription
        isBundle
        reviewAverage
        reviewCount
        fiveStarReviewCount
        fourStarReviewCount
        threeStarReviewCount
        twoStarReviewCount
        oneStarReviewCount
        reviews(first: 10) {
            edges {
                cursor
                node {
                    ...ProductReviewFields
                }
            }
        }
        prices(first: 1) {
            edges {
                node {
                    id
                    netPrice
                    grossPrice
                }
            }
        }
        stockUnit
        checkoutMaxQuantity
        sellingType
        sellingValue
        sellingUnit
        weightPerPieceValue
        weightPerPieceUnit
        incrementValue
        incrementUnit
        backgroundColor
        blobs {
            color
            borderColor
            borderRadius
            borderStyle
            borderWidth
            fontColor
            word
        }
        certifications {
            id
            name
            description
            external
            logoUrl
        }
        ingredients {
            id
            name
            position
            breakdown
            percentage
        }
        story
        mainImage
        images {
            isMain
            alt
            url
            order
            isHover
            isInCollapsedSection
        }
        facets {
            value {
                name
            }
            description
            isMain
        }
        aisles {
            main
            firstLevel {
                name
                slug
            }
            secondLevel {
                name
                slug
            }
            thirdLevel {
                name
                slug
            }
        }
        variant {
            id
            sku
            pricing {
                price
                priceUndiscounted
                baseMeasurePrice
                baseMeasurePriceUndiscounted
            }
        }
        plmId
        rawId
        analyticsId
        barcodes
        itemStatus
        itemType
        sku @client
        href @client
        mainImageAlt @client
        stepperIncrement @client
    }
`;
