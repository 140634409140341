import { gql } from '@apollo/client';
import { ProductFieldsFragment } from './ProductFields.gql';

/**
 * GraphQL Fragment
 *
 */
export const BundleFieldsFragment = gql`
    ${ProductFieldsFragment}
    fragment BundleFields on Bundle {
        id
        name
        slug
        sku
        brand
        description
        longDescription
        seoDescription
        totalPrice
        checkoutMaxQuantity
        bundleAisle {
            main
            firstLevel {
                name
                slug
            }
            secondLevel {
                name
                slug
            }
            thirdLevel {
                name
                slug
            }
        }
        blobs {
            color
            borderColor
            borderRadius
            borderStyle
            borderWidth
            fontColor
            word
        }
        images {
            alt
            url
            order
        }
        facets {
            value {
                name
            }
            description
            isMain
        }
        certifications {
            id
            name
            description
            external
            logoUrl
        }
        products {
            ...ProductFields
            quantity
        }

        barcodes
        rawId
        plmId

        gtin @client
        isBundle @client
        href @client
        mainImage @client
        mainImageAlt @client
        sellingValue @client
        sellingUnit @client
    }
`;
