import { gql } from '@apollo/client';
import { ProductFieldsFragment } from '../../fragments/products/ProductFields.gql';

const PRODUCT_DETAILS_QUERY = gql`
    ${ProductFieldsFragment}
    query Product(
        $slug: String
        $productId: String
        $withAvailability: Boolean = false
        $zipCode: String
    ) {
        product(slug: $slug, productId: $productId) {
            ...ProductFields
        }
        productAvailability(slug: [$slug], zipCode: $zipCode) @include(if: $withAvailability) {
            gtin
            availableOnZipCode
            itemsAvailable
            status
        }
    }
`;

export default PRODUCT_DETAILS_QUERY;
