import { gql } from '@apollo/client';

const PRODUCT_AVAILABILITY_AND_PRICE_QUERY = gql`
    query CatalogPriceAndAvailability(
        $productIds: [String]
        $plmId: [String]
        $slugs: [String]
        $sku: [String]
        $bundleId: [String]
        $zipCode: String
        $storeId: ID
    ) {
        productAvailability(
            sku: $sku
            productId: $productIds
            plmId: $plmId
            bundleId: $bundleId
            slug: $slugs
            zipCode: $zipCode
        ) {
            gtin
            availableOnZipCode
            itemsAvailable
            status
        }
        catalogPrice(
            sku: $sku
            storeId: $storeId
            plmId: $plmId
            bundleId: $bundleId
            productId: $productIds
        ) {
            sku
            isBundle
            price
            discountedPrice
            baseMeasurePrice
            baseMeasureDiscountedPrice
            discountPercentage
            discountValidUntil
        }
    }
`;

export default PRODUCT_AVAILABILITY_AND_PRICE_QUERY;
