import { gql } from '@apollo/client';
import { ProductFieldsForCardWithPriceFragment } from '../../fragments/products/ProductFieldsForCardWithPrice.gql';
import { BundleFieldsForCardFragment } from '../../fragments/products/BundleFieldsForCard.gql';

const PRODUCTS_AND_BUNDLES_CARD_DETAILS_QUERY = gql`
    ${ProductFieldsForCardWithPriceFragment}
    ${BundleFieldsForCardFragment}
    query ProductsAndBundles(
        $plmId: [String]
        $productIds: [String]
        $bundleIds: [String]
        $sku: [String]
        $zipCode: String
        $storeId: ID
        $withApplicableRules: Boolean = false
        $withAvailability: Boolean = false
        $withCatalogPrice: Boolean = false
        $withProducts: Boolean = false
        $withBundles: Boolean = false
        $nbProducts: Int = 1
        $nbBundles: Int = 1
    ) {
        products(productId: $productIds, sku: $sku, plmId: $plmId, first: $nbProducts)
            @include(if: $withProducts) {
            totalCount
            edges {
                node {
                    ...ProductFieldsForCardWithPrice
                    applicableRules(storeId: $storeId) @include(if: $withApplicableRules) {
                        id
                        title
                        description
                        campaignIcon
                        priority
                        conditions {
                            id
                            includeAll
                            type
                            evaluationType
                            objects
                        }
                        benefits {
                            id
                            discountString
                            type
                        }
                    }
                }
            }
        }
        bundles(bundleId: $bundleIds, first: $nbBundles) @include(if: $withBundles) {
            totalCount
            edges {
                node {
                    ...BundleFieldsForCard
                    applicableRules(storeId: $storeId) @include(if: $withApplicableRules) {
                        id
                        title
                        description
                        campaignIcon
                        priority
                        conditions {
                            id
                            includeAll
                            type
                            evaluationType
                            objects
                        }
                        benefits {
                            id
                            discountString
                            type
                        }
                    }
                }
            }
        }
        productAvailability(productId: $productIds, zipCode: $zipCode)
            @include(if: $withAvailability) {
            gtin
            availableOnZipCode
            itemsAvailable
            status
        }
        catalogPrice(storeId: $storeId, sku: $sku) @include(if: $withCatalogPrice) {
            sku
            isBundle
            price
            discountedPrice
            baseMeasurePrice
            baseMeasureDiscountedPrice
        }
    }
`;

export default PRODUCTS_AND_BUNDLES_CARD_DETAILS_QUERY;
