import { gql } from '@apollo/client';

/**
 * GraphQL Fragment
 *
 */
export const ProductFieldsForCardWithPriceFragment = gql`
    fragment ProductFieldsForCardWithPrice on Product {
        id
        gtin
        name
        slug
        brand
        isBundle
        reviewAverage
        reviewCount
        stockUnit
        checkoutMaxQuantity
        mainImage
        sellingType
        sellingValue
        sellingUnit
        weightPerPieceValue
        weightPerPieceUnit
        incrementValue
        incrementUnit

        plmId
        rawId
        analyticsId

        sku @client
        href @client
        mainImageAlt @client
        stepperIncrement @client
    }
`;
