import { gql } from '@apollo/client';
import { BundleFieldsFragment } from '../../fragments/products/BundleFields.gql';
const KIT_DETAILS_QUERY = gql`
    ${BundleFieldsFragment}
    query Bundle($slug: String!, $withAvailability: Boolean = false, $zipCode: String) {
        bundle(slug: $slug) {
            ...BundleFields
        }
        productAvailability(slug: [$slug], zipCode: $zipCode) @include(if: $withAvailability) {
            gtin
            availableOnZipCode
            itemsAvailable
            status
        }
    }
`;
export default KIT_DETAILS_QUERY;
