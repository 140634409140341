import React from 'react';
import Head from 'next/head';
import cx from 'classnames';
import Link from '../../atoms/Link';
import Image from '../../atoms/Image';
import Button from '../../atoms/Button';

const NOT_FOUND_IMAGE =
    'https://us-central1-yema-cdn.cloudfunctions.net/cdn/api/v1/yema-plm/images/view/assets/yema-image-notfound.png';

const NotFound = ({ className, ...otherProps }) => {
    return (
        <>
            <Head>
                <meta name='robots' content='noindex' />
            </Head>
            <div className={cx('relative h-96 md:h-[607px]', className)} {...otherProps}>
                <Image
                    layout='fill'
                    objectFit='cover'
                    objectPosition='center'
                    src={NOT_FOUND_IMAGE}
                    alt='La página que buscas no existe'
                />
                <div className='absolute top-0 w-full py-6 text-center'>
                    <h2>¡Ups! Parece que llegaste a un lugar inesperado</h2>
                    <p>No pasa nada</p>
                    <Link href='/'>
                        <Button variants='primary' size='sm' type='button'>
                            Regresar al inicio
                        </Button>
                    </Link>
                </div>
            </div>
        </>
    );
};
NotFound.defaultProps = {};

NotFound.propTypes = {};

export default NotFound;
