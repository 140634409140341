import { gql } from '@apollo/client';

/**
 * GraphQL Fragment
 *
 */
export const BundleFieldsForCardFragment = gql`
    fragment BundleFieldsForCard on Bundle {
        id
        name
        slug
        sku
        brand
        checkoutMaxQuantity
        stockQuantity
        totalPrice
        images {
            alt
            url
            order
        }
        products {
            id
            gtin
            name
            quantity
        }

        rawId
        plmId

        gtin @client
        isBundle @client
        href @client
        mainImage @client
        mainImageAlt @client
        sellingValue @client
        sellingUnit @client
    }
`;
