import { get_ } from 'sf-modules';
import { initializeApollo } from '@yema/commerce/lib/apollo';
import PRODUCTS_BUNDLES_APPLICABLE_RULES_QUERY from '@yema/commerce/graphql/queries/products/products-bundles-applicable-rules.gql';
import { APOLLO_GRAPHQL_TIMEOUT } from 'constants/constants';

const client = initializeApollo();

export async function fetchProductsPromotions({
    gtinsToFetch = [],
    productIds = [],
    plmIds = [],
    bundlesIdToFetch = [],
    storeId = null,
}) {
    if (!gtinsToFetch?.length && !productIds?.length && !plmIds?.length) return null;

    let queryVariables = {};

    if (plmIds && plmIds.length) {
        queryVariables.plmId = plmIds;
    } else if (productIds && productIds.length) {
        queryVariables.productIds = productIds;
    } else {
        queryVariables.sku = gtinsToFetch;
    }

    const productsAndBundlesPromotions = await client.query({
        query: PRODUCTS_BUNDLES_APPLICABLE_RULES_QUERY,
        context: {
            timeout: APOLLO_GRAPHQL_TIMEOUT,
        },
        variables: {
            ...queryVariables,
            bundlesID: bundlesIdToFetch,
            firstGtins: gtinsToFetch && gtinsToFetch.length ? gtinsToFetch.length : 1,
            firstBundles: bundlesIdToFetch && bundlesIdToFetch.length ? bundlesIdToFetch.length : 1,
            storeId: storeId,
        },
    });

    const productsPromotions = get_(
        productsAndBundlesPromotions,
        ['data', 'products', 'edges'],
        []
    ).map((p) => get_(p, ['node']));

    const bundlesPromotions = get_(
        productsAndBundlesPromotions,
        ['data', 'bundles', 'edges'],
        []
    ).map((p) => get_(p, ['node']));

    return {
        productsPromotions: productsPromotions,
        bundlesPromotions: bundlesPromotions,
    };
}
