import { gql } from '@apollo/client';

/**
 * GraphQL Fragment
 *
 */
export const ProductReviewFieldsFragment = gql`
    fragment ProductReviewFields on ProductReview {
        id
        productName
        productSku
        reviewerName
        rating
        title
        content
        isVerified
        created
    }
`;
