import { initializeApollo } from '@yema/commerce/lib/apollo';
import { trackError } from '@yema/commerce/utils/error';
import { get_, dataTransformers } from 'sf-modules';
import PRODUCT_DETAILS_QUERY from '@yema/commerce/graphql/queries/products/product-details.gql';
import KIT_DETAILS_QUERY from '@yema/commerce/graphql/queries/products/kit-details.gql';
import PRODUCTS_AND_BUNDLES_CARD_DETAILS_QUERY from '@yema/commerce/graphql/queries/products/products-bundles-card-details.gql';
import PRODUCT_AVAILABILITY_AND_PRICE_QUERY from '@yema/commerce/graphql/queries/products/product-availability-and-price.gql';
import { APOLLO_GRAPHQL_TIMEOUT } from 'constants/constants';

/** Get products from PIM */
export async function getProducts({
    productPLMIds = [],
    productsGtins = [],
    bundlesId = [],
    zipCode = '',
    storeId = null,
    withApplicableRules = false,
}) {
    if ((!productsGtins && !bundlesId) || (!productsGtins.length && !bundlesId.length))
        return {
            products: [],
            bundles: [],
            availabilities: [],
            catalogPrices: [],
        };

    let queryVariables = {};

    if (productPLMIds.length) {
        queryVariables.plmId = productPLMIds;
    } else {
        queryVariables.sku = productsGtins;
    }

    const client = initializeApollo();

    const gtinsString = [...productsGtins, ...bundlesId]; // Hack until this is modified in Saleor: gtins should be the same type.
    const response = await client.query({
        query: PRODUCTS_AND_BUNDLES_CARD_DETAILS_QUERY,
        context: { timeout: APOLLO_GRAPHQL_TIMEOUT },
        variables: {
            ...queryVariables,
            zipCode: zipCode,
            storeId: storeId,
            withApplicableRules: withApplicableRules,
            withAvailability: Boolean(zipCode && gtinsString.length),
            withCatalogPrice: Boolean(storeId && gtinsString.length),
            withProducts: Boolean(productsGtins.length),
            withBundles: Boolean(bundlesId.length),
            nbProducts: productsGtins.length,
            nbBundles: bundlesId.length,
        },
        fetchPolicy: 'network-only', // Local-fields requires to enable cache (so 'no-cache' is not an option)
    });

    const data = get_(response, ['data']);
    const products = get_(data, ['products', 'edges'], []).map((item) => get_(item, ['node'], {}));
    const bundles = get_(data, ['bundles', 'edges'], []).map((item) => get_(item, ['node'], {}));

    return {
        ...response,
        products: products,
        bundles: bundles,
        availabilities: get_(data, ['productAvailability'], []),
        catalogPrices: get_(data, ['catalogPrice'], []),
    };
}

export async function getProductsAvailability({
    gtins = [],
    productIds = [],
    plmIds = [],
    zipCode = '',
    storeId = null,
}) {
    if (!plmIds?.length && !productIds?.length && !gtins?.length) return {};

    const client = initializeApollo();

    let queryVariables = {};

    if (plmIds && plmIds.length) {
        queryVariables.plmId = plmIds;
    } else if (productIds && productIds.length) {
        queryVariables.productIds = productIds;
    } else {
        queryVariables.sku = gtins;
    }

    const response = await client
        .query({
            query: PRODUCT_AVAILABILITY_AND_PRICE_QUERY,
            context: { timeout: APOLLO_GRAPHQL_TIMEOUT },
            variables: {
                ...queryVariables,
                zipCode,
                storeId,
            },
            fetchPolicy: 'network-only',
        })
        .then((res) => res)
        .catch((e) => {
            trackError({
                error: e,
                extras: {
                    label: 'No se encontraron productos',
                    productId: productIds,
                    gtins: gtins,
                    zipCode: zipCode,
                    error: e,
                },
            });
            return null;
        });

    return {
        availabilities: get_(response, ['data', 'productAvailability'], []),
        prices: get_(response, ['data', 'catalogPrice'], []),
    };
}

export async function getProductOrBundle({ client, slug, isBundle = false }) {
    if (!slug) return null;

    let responseProduct;
    try {
        responseProduct = await client.query({
            query: isBundle ? KIT_DETAILS_QUERY : PRODUCT_DETAILS_QUERY,
            context: { timeout: APOLLO_GRAPHQL_TIMEOUT },
            variables: {
                slug,
                withAvailability: true, //zipCode !== null && zipCode !== '',
                zipCode: '', // Load availability for default catalog - required so that Google Crawlers can have stock information
            },
            fetchPolicy: 'network-only', // Local-fields requires to enable cache (so 'no-cache' is not an option)
        });
    } catch (e) {
        // Do nothing: no product were found, we will throw a 404 page.
    }

    if (!responseProduct) return null;

    const data = get_(responseProduct, ['data']);
    const bundleOrProductKey = isBundle ? 'bundle' : 'product';
    const availability = get_(data, ['productAvailability', 0]);

    return {
        ...responseProduct,
        product: dataTransformers.transformProductOrBundleFromPIM(
            isBundle,
            get_(data, [bundleOrProductKey]),
            availability,
            true
        ),
        productBreadcrumb: dataTransformers.getProductBreadcrumb(
            get_(data, [bundleOrProductKey]),
            isBundle
        ),
    };
}
